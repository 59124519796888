<template>
    <div class="detail-card">
        <div class="detail-card-title">
            <rsck-radio
                    :checked="selectAll"
                    @onChooseClick="setSelectAllStatus"
                    class="marin-title"
            >
                <div class="title">{{title}}</div>
            </rsck-radio>
            <div class="refund" @click="goToRefund" v-if="cardInfo.status == 1">退款></div>
        </div>
        <div class="detail-card-content">
            <rsck-radio
                    class="box-m-t-21"
                    v-for="(item, index) in finalDataList"
                    :checked="item.checked"
                    @onChooseClick="chooseGoods(index)"
                    :disabled="item.is_checked == false"
            >
                <coupon-goods-v1
                        :goods-id="item.goods_id"
                        :card-id="cardId"
                        :img_url="item.cover"
                        :description="item.title"
                        :text="'价值'+item.market_price+'元'"
                        :options="item.options"
                        :selectOptions="item.selectOption"
                        @onSelect="onSelect(index)"
                        :disabled="item.is_checked == false"
                        :status="item.status"
                >
                    <div class="warning box-m-t-12" v-if="typeof item.warning != 'undefined' && item.warning != ''">
                        {{item.warning}}
                    </div>
                </coupon-goods-v1>
            </rsck-radio>
        </div>


    </div>
</template>

<script>
  import CheckedV1 from '../community/checked/checkedV1'
  import GoodsV3 from '../community/goods/goodsV3'
  import StepperV1 from '../community/stepper/stepperV1'
  import RsckRadio from '../../components/common/rsck-radio'
  import CouponGoodsV1 from '@/template/coupon-card/CouponGoodsV1'

  export default {
    name: 'coupon-card-detail-v1',
    components: {CouponGoodsV1, RsckRadio, StepperV1, GoodsV3, CheckedV1},
    props: {
      title: {
        type: String,
        default: '自提商品',
      },
      lists: {
        type: Array,
        default () {
          return []
        },
      },
      info: {
        type: Object,
        default () {
          return {}
        },
      },
      checked: {
        type: Array,
        default () {
          return []
        },
      },
      params: null,
      cardId: {
        type: [String, Number],
        default: 0
      },
      cardInfo:{
        type:Object,
        default:() => {
          return {}
        }
      }

    },
    data () {
      return {
        showDiscountDetail: false,
        show: false,
        popupOptions: [],
        getType: 0,
        finalDataList: [],
        selectOptionsIndex: 0,
        selectAll: false,
      }
    },
    mounted () {
      //计算标题可以使用的长度
      this.$props.lists.forEach(item => {
        item.checked = false
        item.selectOption = item.select_option
        this.finalDataList.push(item)
      })

      console.log('this.finalDataList',this.finalDataList)
    },
    methods: {
      chooseGoods (index) {
        this.finalDataList[index].checked = !this.finalDataList[index].checked
        if (this.finalDataList[index].checked == false) {
          this.finalDataList[index].selectOption = 0
        } else {
          this.finalDataList[index].selectOption = this.finalDataList[index].select_option
        }
        this.checkSelectAllStatus()
        this.$forceUpdate()

      },
      onChoose (value) {
        let index = this.selectOptionsIndex
        if (value == this.finalDataList[index].selectOption) {
          this.finalDataList[index].selectOption = 0
          this.finalDataList[index].checked = false
          this.getType = 0
        } else {
          this.finalDataList[index].selectOption = value
          this.finalDataList[index].checked = true
          this.getType = value
        }

        this.checkSelectAllStatus()
        this.show = false

        this.$forceUpdate()
      },
      setChoose(index,value){
        if (value == this.finalDataList[index].selectOption) {
          this.finalDataList[index].selectOption = 0
          this.finalDataList[index].checked = false
          this.getType = 0
        } else {
          this.finalDataList[index].selectOption = value
          this.finalDataList[index].checked = true
          this.getType = value
        }

        this.checkSelectAllStatus()
        this.$forceUpdate()
      },
      onSelect (index) {
        this.popupOptions = this.finalDataList[index].options
        this.selectOptionsIndex = index
        this.getType = this.finalDataList[index].selectOption
        this.checkSelectAllStatus()
        this.$emit('onTypeSelect',{index:index,popupOptions:this.popupOptions,getType:this.getType})
      },
      setSelectAllStatus () {
        this.selectAll = !this.selectAll
        this.finalDataList.forEach((item, index) => {
          if (this.selectAll == true) {
            if (item.is_checked) {
              item.checked = true
              item.selectOption = item.select_option
            }
          } else {
            item.checked = false
            item.selectOption = 0
          }
        })
      },
      checkSelectAllStatus () {
        //判断是否是全选状态
        let selectNumber = 0
        let canSelectNumber = 0//可以选择的数量
        this.finalDataList.forEach((item, index) => {
          if (item.checked == true) {
            selectNumber++
          }
          if (item.is_checked) {
            canSelectNumber++
          }
        })
        if (selectNumber == canSelectNumber) {
          this.selectAll = true
        } else {
          this.selectAll = false
        }
      },
      getUserChoose () {
        return this.finalDataList
      },
      goToRefund () {
        let query = {
          card_id: this.$props.cardId
        }
        let options = encodeURIComponent(JSON.stringify(query))
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/couponCard/refund?query=' + options,
        })
      },
    },
  }
</script>

<style scoped lang="scss">
    .detail-card {
        background: #fff;
        padding: 18px 15px 20px 15px;
        flex-wrap: wrap;

        .detail-card-title {
            width: 100%;
            display: flex;
            height: 16px;
            align-items: center;

            .marin-title {
                flex: 1;
            }

            .title {
                color: #333333;
                font-size: 14px;
                flex: 1;
            }

            .refund {
                color: #939393;
                font-size: 14px;
                width: 80px;
                display: flex;
                justify-content: flex-end;
            }
        }

        .detail-card-content {
            width: 100%;
            display: flex;
            overflow: hidden;
            flex-wrap: wrap;

            .warning {
                width: 100%;
                background: #FFF9EC;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                border-radius: 4px;
                font-size: 12px;
                color: #DF8621;
                padding: 8px 10px;
            }
        }
    }



</style>