<template>
    <div class="goods">
        <div class="goods-info">
            <div class="img" @click="viewGoodsDetail">
                <img :src="img_url"/>
                <div class="status-image">
                    <img v-if="status == 5"
                         src="https://api.mengjingloulan.com/storage/wechat/common/coupon-card-goods-expired.png"/>
                    <img v-if="status == 4"
                         src="https://api.mengjingloulan.com/storage/wechat/common/coupon-card-goods-month-get.png"/>
                    <img v-if="status == 3"
                         src="https://api.mengjingloulan.com/storage/wechat/common/coupon-card-goods-week-get.png"/>
                    <img v-if="status == 2"
                         src="https://api.mengjingloulan.com/storage/wechat/common/coupon-card-goods-is-over.png"/>
                </div>
            </div>
            <div class="content">
                <div class="description" @click="viewGoodsDetail">{{ description }}</div>
                <div class="rule">
                    <rsck-select @onSelect="onSelectDo" :text="showText"
                                 :active="selectOptions != 0 && disabled == false"></rsck-select>
                </div>
                <div class="text" @click="viewGoodsDetail">
                    {{ text }}
                </div>
            </div>
        </div>
        <div class="goods-extends" @click="viewGoodsDetail">
            <slot></slot>
        </div>

    </div>
</template>

<script>
  import RsckSelect from '@/components/common/rsck-select'
  import RsckRadio from '@/components/common/rsck-radio'

  export default {
    name: 'CouponGoodsV1',
    components: {RsckRadio, RsckSelect},
    props: {
      goodsId: {
        type: [String, Number],
        default: 0
      },
      cardId: {
        type: [String, Number],
        default: 0
      },
      img_url: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
        default: () => {
          return [
            {label: '选项一', value: 0},
            {label: '选项一', value: 1},
            {label: '选项二', value: 2},
          ]
        }
      },
      selectOptions: {
        type: [String, Number],
        default: 0
      },
      disabled: {
        type: Boolean,
        default: false
      },
      status: {
        type: [String, Number],
        default: 0//根据此状态，渲染不同的展示图片
      }
    },
    computed: {
      getType () {
        return this.$props.selectOptions
      }
    },
    data () {
      return {
        show: false,
        showText: '领取方式'
      }
    },
    watch: {
      selectOptions: {
        handler (newV, oldV) {
          let string = this.showText
          for (let i in this.$props.options) {
            if (this.$props.options[i].value == this.$props.selectOptions) {
              string = this.$props.options[i].lable
            }
          }
          this.showText = string

        },
        immediate: true
      }
    },
    methods: {
      onRuleClick () {
        this.$emit('onRuleClick', this.$props.param)
      },
      onSelectDo () {
        this.$emit('onSelect')
      },
      viewGoodsDetail () {
        //首先判断是否已经选择了领取方式
        if (this.getType == 0) {
          this.$emit('onSelect')
          return
        }
        let params = {
          goods_id: this.$props.goodsId,
          card_id: this.$props.cardId,
          type: this.$props.selectOptions
        }
        let options = encodeURIComponent(JSON.stringify(params))
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/couponCard/goods-detail?query=' + options,
        })
        // this.$router.push({
        //   path: '/community/map',
        //   query: {
        //     site_point_id: this.express.site_point.site_point_id
        //   },
        // })
      }
    },
  }
</script>

<style scoped lang="scss">
    .goods {
        display: flex;
        flex: 1;
        flex-wrap: wrap;

        .goods-info {
            width: 100%;
            display: flex;
            position: relative;

            .img {
                width: 82px;
                height: 82px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                border-radius: 8px;
                margin-right: 12px;
                position: relative;
                overflow: hidden;

                img {
                    width: 82px;
                    height: 82px;
                }

                .status-image {
                    position: absolute;
                    right: -2px;
                    top: -2px;

                    img {
                        width: 51px;
                        height: 51px;
                    }
                }
            }

            .content {
                flex: 1;
                flex-direction: row;
                flex-wrap: wrap;
                display: flex;

                .description {
                    height: 15px;
                    font-size: 14px;
                    color: #333333;
                    font-weight: bold;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    word-break: break-all;
                }

                .rule {
                    width: 100%
                }

                .text {
                    font-size: 16px;
                    color: #C10000;
                    font-weight: bold;
                    display: flex;
                    align-items: flex-end;
                }
            }
        }

        .goods-extends {
            width: 100%;
        }

        .popup-window {
            padding: 30px;
        }

        .radio-line {
            width: 100%;
            margin-bottom: 10px;
        }

        .radio-text {
            color: #333;
            font-size: 12px;
        }
    }
</style>