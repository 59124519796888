<template>
    <!--礼品卡详情页面-->
    <div class="coupon-card-detail-container">
        <div id="minirefresh" class="minirefresh-wrap"
             :style="{height:scrollHeight+'px'}">
            <div class="minirefresh-scroll">
                <template v-if="showResult">
                    <div class="details-info">
                        <coupon-card-detail-v1 ref="settingPage" :title="cardInfo.card_name"
                                               :lists="goodsList" :card-id="order_id"
                                               :cardInfo="cardInfo"
                                               @onTypeSelect="onTypeSelect"></coupon-card-detail-v1>
                        <div class="space-line" style="height: 60px;"></div>
                    </div>

                </template>
            </div>
        </div>

        <div class="bottom-button">
            <!--<div class="rules" @click="viewRules">-->
                <!--<img src="https://api.mengjingloulan.com/storage/wechat/coupon-card/rule.png"/> 礼品卡使用规则-->
            <!--</div>-->
            <!--<div class="line">-->

            <!--</div>-->
            <div class="use-log" @click="viewCouponUseLog">
                <img src="https://api.mengjingloulan.com/storage/wechat/coupon-card/use-log.png"/> 领取记录
            </div>
            <!--<div class="line">-->
            <!--</div>-->
            <div class="buttons">
                <div class="use" @click="doUse">
                    立即领取
                </div>
            </div>
        </div>

        <loading-v1 ref="loading"></loading-v1>

        <nut-popup
                :style="{ height:'60%',background:'#fff' }"
                v-model="show"
                position="bottom"
                :closeable="true"
                :round="true"
        >
            <div class="popup-container">
                <div class="title">礼品卡使用规则</div>
                <div class="rules" v-html="cardInfo.rule_content"></div>
            </div>

        </nut-popup>

        <nut-popup
                v-model="showVisible"
                :closeable="true"
                :round="true"
                :close-on-click-overlay="false"
                position="bottom"

        >
            <div class="popup-window">
                <div class="radio-line" v-for="(item,index) in popupOptions" :key="index">
                    <rsck-radio :checked="item.value == getType"
                                @onChooseClick="onChoose(item.value)">
                        <div class="radio-text">{{item.lable}}</div>
                    </rsck-radio>
                </div>
            </div>
        </nut-popup>
    </div>
</template>

<script>
  import CouponCardDetailV1 from '../../template/coupon-card/coupon-card-detail-v1'
  import LoadingV1 from '@/template/community/loading/loadingV1'
  import RsckRadio from '@/components/common/rsck-radio'
  // import MiniRefresh from 'minirefresh';
  import MiniRefresh from 'minirefresh';
  import 'minirefresh/dist/debug/minirefresh.css'

  export default {
    name: 'card-details',
    components: {RsckRadio, LoadingV1, CouponCardDetailV1},
    mounted () {
      document.title = '礼品卡详情'
      this.detailHeight = window.innerHeight - 50
      let query = this.$route.query
      this.order_id = query.order_id
      this.getCardInfo()
      this.canContinue = true
      this.refresh()
    },
    data () {
      return {
        show: false,
        detailHeight: 0,
        order_id: 0,
        cardInfo: {},
        showResult: false,
        goodsList: [],
        scrollHeight: window.innerHeight,
        popupOptions: [],
        showVisible: false,
        getType: 0,
        optionsIndex: 0,
        canContinue: false,
      }
    },
    methods: {
      refresh () {
        const that = this
        this.miniRefresh = new window.MiniRefresh({
          container: '#minirefresh',
          down: {
            offset: 50,// 触发下拉的阈值，当下拉距离大于这个阈值后，在松开时会触发下拉刷新
            callback: function () {
              that.getCardInfo()
              that.miniRefresh.endDownLoading()
            }
          },
          up: {
            isLock: true
          },
          isScrollBar: false
        })
      },
      getCardInfo () {
        this.$refs.loading.show()
        this.$api.couponCard.getCouponOrderInfo({card_id: this.order_id}).then(response => {
          this.cardInfo = response.data.basic
          let goodsList = []
          response.data.goodList.forEach(item => {
            let temp = {}
            temp.title = item.title
            temp.goods_id = item.goods_id
            temp.cover = item.cover
            temp.number = item.claim_number
            temp.market_price = item.market_price
            temp.options = item.select_mail_type
            temp.select_option = item.mail_type
            temp.tip = item.tip
            temp.shipping_fee = item.shipping_fee
            temp.status = item.status
            temp.is_checked = item.is_checked
            goodsList.push(temp)
          })
          this.goodsList = goodsList
          this.showResult = true
          this.$refs.loading.hide()
        }).catch(error => {
          this.$toast.fail(error.message)
          this.$refs.loading.hide()
        })
      },
      doUse () {
        let setting = this.$refs.settingPage.getUserChoose()
        //过滤出用户勾选了的信息
        let data = []
        setting.forEach(item => {
          if (item.checked) {
            data.push(item)
          }
        })
        if (data.length == 0) {
          this.$toast.warn('您还未选择任何商品进行领取')
          return
        }

        //组合传递的参数
        let params = {
          card_id: this.order_id,
          goods_id: ''
        }
        let goods_id = []
        data.forEach(item => {
          let temp = []
          temp.push([item.goods_id, item.selectOption].join('_'))
          goods_id.push(temp)
        })

        params.goods_id = goods_id.join('|')

        if (this.canContinue) {
          this.canContinue = false
          if (this.$platform.wxsdk.isWechat()) {
            let options = encodeURIComponent(JSON.stringify(params))
            this.$platform.wxsdk.wxRoute({
              type: 'navigateTo',
              url: '/web/couponCard/card-use-apply?query=' + options,
            })
          }

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      },
      viewCouponUseLog () {
        let params = {
          card_id: this.order_id,
        }
        let options = encodeURIComponent(JSON.stringify(params))
        if (this.canContinue) {
          this.canContinue = false
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/couponCard/card-get-log?query=' + options,
          })
          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      },
      viewRules () {
        this.show = true
      },
      onTypeSelect (data) {
        console.log('onTypeSelect', data)
        this.showVisible = true
        this.popupOptions = data.popupOptions
        this.getType = data.getType
        this.optionsIndex = data.index
      },
      onChoose (value) {
        let index = this.optionsIndex
        if (value == this.getType) {
          this.getType = 0
        } else {
          this.getType = value
        }
        this.showVisible = false
        this.$refs.settingPage.setChoose(this.optionsIndex, this.getType)
      },
    }
  }
</script>

<style scoped lang="scss">
    .coupon-card-detail-container {
        min-height: 100vh;
        background: #f6f6f6;
        position: relative;
        display: flex;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .details-info {
            padding: 10px 0;
            width: 100%;
            overflow-y: scroll;

            .detail-buttons {
                width: 100%;

                .buttons {
                    display: flex;
                    padding: 15px;

                    .use {
                        width: 100%;
                        height: 48px;
                        background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
                        -webkit-border-radius: 6px;
                        -moz-border-radius: 6px;
                        border-radius: 6px;
                        font-size: 14px;
                        color: #FFFFFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .space-line {
                width: 100%;
                height: 25px;
            }

        }

        .bottom-button {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 58px;
            background: #fff;
            border-top: #EBEBEB solid 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #F46A17;
            z-index: 9999;
            padding-bottom: 20px;
            .rules {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 3px;
                }
            }

            .line {
                height: 22px;
                width: 1px;
                background: #EBEBEB;
            }

            .use-log {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 3px;
                }
            }

            .buttons {
                flex: 1;
                padding: 0 10px;
                /*width: 100px;*/
                display: flex;
                .use {
                    flex: 1;
                    height: 44px;
                    background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
                    -webkit-border-radius: 22px;
                    -moz-border-radius: 22px;
                    border-radius: 22px;
                    font-size: 14px;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

        }
    }

    .popup-container {
        padding: 30px;

        .title {
            width: 100%;
            font-size: 14px;
            color: #333;
        }

        .rules {
            margin-top: 10px;
            line-height: 25px;
            font-size: 14px;
            color: #939393;
        }
    }

    .popup-window {
        padding: 50px 50px 90px 50px;
        background: #fff;

        .radio-line {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .radio-text {
                font-size: 12px;
                line-height: 16px;
                color: #333;
            }
        }

    }

    .minirefresh-scroll {
        background: #f6f6f6
    }
</style>